*:focus {
  outline: none;
}

.pdfview {
  width: '100vw';
  display: 'block';
  margin: '0 auto';
}
.spaced {
  padding: 0 10px;
}

.favicon {
  width: 35px;
  border-radius: 23px;
  padding-right: 5px;
}

.bhajanRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 21px;
  height: 100%;
  border-bottom: 1px solid black;
}

.App-header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: orange;
  height: 56px;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;
}

.rest {
  padding-top: 56px;
}

.back::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(225deg);
  margin-left: 4px;
  transition: transform 0.5s;
}

.back:hover::before {
  transform: translate(-10px, 0) rotate(225deg);
}

.restPage {
  padding-top: 70px;
  display: block;
  margin: 0 auto;
  max-width: 800px;
}

.pdf {
  display: block;
  margin: 0 auto;
}

.pdf-next {
  z-index: 2;
  position: fixed;
  height: calc(100vh - 56px);
  width: 50vw;
  top: 56px;
  right: 0;
}

.pdf-previous {
  z-index: 2;
  position: fixed;
  height: calc(100vh - 56px);
  width: 50vw;
  top: 56px;
  left: 0;
}

.Search_RightSide {
  display: flex;
  align-items: center;
}

input {
  width: 100%;
  /*margin: 8px 0;*/
  display: inline-block;
  border: 1px solid #ccc;
  /*box-shadow: inset 0 1px 3px #ddd;*/
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  box-sizing: border-box;
  padding: 12px 20px;
  font-size: 20px;
}

.title {
  justify-content: center;
  align-content: center;
  font-size: 20px;
  color: white;
  width: 100px;
  padding-right: 15px;
  text-decoration: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

mark {
  background-color: yellow;
  padding: 0;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.rightAligned {
  text-align: right;
}

.pdf-next-arrow {
  position: fixed;
  z-index: 2;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  top: calc(50% + 56px);
  left: 20px;
  animation: grow-next 1s forwards;
}

.pdf-prev-arrow {
  position: fixed;
  z-index: 2;
  transform: rotate(-45deg);
  top: calc(50% + 56px);
  right: 20px;
  animation: grow-prev 1s forwards;
}

@keyframes grow-next {
  100% {
    transform: scale(2) rotate(135deg);
  }
}

@keyframes grow-prev {
  100% {
    transform: scale(2) rotate(-45deg);
  }
}
